import { Injectable } from '@angular/core';
import {
    combineLatest,
    from,
    map,
    Observable,
    of,
    shareReplay,
    startWith,
    Subject,
    switchMap,
    tap
} from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { GetUserInventoryResult } from '../playfab/client/get-user-inventory-result';
import { PlayFabClient } from '../playfab/client/playfab-client';

@Injectable({
    providedIn: 'root'
})
export class LibraryService {
    private refreshed = new Subject<boolean>();
    private readonly nullInventory: GetUserInventoryResult = {
        Inventory: [],
        VirtualCurrency: {},
        VirtualCurrencyRechargeTimes: []
    };

    constructor(
        private readonly playfab: PlayFabClient,
        private readonly authService: AuthService
    ) {
        this.user = combineLatest([
            this.authService.user,
            this.refreshed.pipe(startWith(true))
        ]).pipe(
            switchMap(x => {
                const user = x[0];
                if (user) {
                    return from(this.playfab.getUserInventory({}, user.login));
                }

                return of(this.nullInventory);
            }),
            tap(x => {
                this.userSnapshot = x;
            }),
            shareReplay(1)
        );
    }

    public user: Observable<GetUserInventoryResult>;
    public userSnapshot: GetUserInventoryResult;

    public refresh() {
        this.refreshed.next(true);
    }
}
